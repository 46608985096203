<template>
  <div class="vuse-content-wrapper courses">
    <!-- <v-row no-gutters align="center" justify="space-between">
      <smart-breadcrumbs :items="navigationElements" />
    </v-row> -->
    <!-- Content -->
    <v-card flat>
      <div class="filters">
        <help class="mx-2 help--crud" :helpId="43" />
        <h2 class="text-center align-text-center">Cursos</h2>
        <v-row justify="end">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="primary"
                dark
                class="mb-2"
                style="float: right"
                @click="handleCreate()"
                >Crear
              </v-btn>
            </template>

            <span>Crear Curso</span>
          </v-tooltip>
        </v-row>

        <label>Filtrar por:</label>
        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row mx-0">
          <v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              />
            </v-col>
          </v-row>
          <v-btn
            color="primary"
            style="min-width: auto; position: absolute; right: 50px; height: 25px"
            dark
            class="px-1 py-1"
            v-if="filterActivated"
            @click.prevent="
              filterActivated = false
              cleanFilters()
              getCourses()
            "
          >
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="primary"
                style="min-width: auto; position: absolute; right: 10px; height: 25px"
                dark
                class="px-1 py-1"
                @click.prevent="
                  filterActivated = true
                  getCourses()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :extraButtoms="extraButtoms"
        :showCreate="false"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
        @handleLessonsRedirect="handleLessonsRedirect($event)"
        @handleExamRedirect="handleExamRedirect($event)"
      />
    </v-card>
    <modal name="crudModal" :width="widthDialog" :adaptive="true">
      <v-card class="pa-2 px-md-4" flat>
        <h2 class="text-center" v-if="flow === 'create'">Crear Curso</h2>
        <v-row
          v-if="flow === 'edit'"
          align="center"
          class="mb-4 mx-0 text-center flex-column"
        >
          <h2 class="">Editar Curso</h2>
          <span class="text-body-2"> {{ course.name !== '' ? course.name : '' }}</span>
        </v-row>

        <v-row no-gutters>
          <v-form ref="formCompany" v-model="valid">
            <v-stepper v-model="step" vertical flat>
              <v-stepper-step :complete="step > 1" editable step="1">
                Datos del curso
              </v-stepper-step>

              <v-stepper-content step="1">
                <span class="caption" v-if="flow === 'edit' && item.created_at"
                  >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
                  {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
                >
                <v-row class="mx-0 mt-2" align="center"
                  ><v-col cols="12" lg="5" md="6">
                    <v-text-field
                      counter
                      :rules="[rules.required, rules.max]"
                      label="Nombre"
                      v-model="course.name"
                    />
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-checkbox
                      :label="course.active ? 'Activo' : 'Inactivo'"
                      v-model="course.active"
                    /> </v-col
                  ><v-col cols="12"
                    ><vue-editor
                      class="text-box"
                      placeholder="Descripción"
                      use-custom-image-handler
                      @image-added="handleImageAdded"
                      v-model="course.description"
                  /></v-col>
                </v-row>

                <v-btn small color="primary" @click="step = 2"> Continuar </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="step > 2" editable step="2">
                Pre-requisitos
              </v-stepper-step>

              <v-stepper-content step="2">
                <v-col cols="12"
                  ><v-row class="mx-0" style="align-items: center">
                    <label class="caption mr-2">Prelación:</label>
                    <multiselect
                      v-model="course.priority"
                      label="name"
                      track-by="id"
                      placeholder="Seleccionar"
                      open-direction="bottom"
                      :options="courses"
                      :multiple="false"
                      :searchable="true"
                      :loading="isLoading"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :max-height="600"
                      :show-no-results="false"
                      :hide-selected="false"
                      @search-change="findCourses"
                    />
                  </v-row>
                  <span class="caption text--secondary">
                    Seleccione el curso que se requiere aprobar antes de poder visualizar
                    este nuevo curso <strong>{{ course.name }}</strong>
                  </span>
                </v-col>
                <v-btn small color="primary" @click="step = 3" class="mr-2">
                  Continuar
                </v-btn>
                <v-btn small @click="step = 1" color="#adadb0" dark> Volver </v-btn>
              </v-stepper-content>

              <v-stepper-step step="3" :complete="step > 3">
                Archivos multimedia
              </v-stepper-step>
              <v-stepper-content step="3">
                <v-row class="mx-0 mb-4">
                  <v-col cols="12" lg="4" md="6">
                    <v-card elevation="4">
                      <v-card-text
                        class="text-center"
                        style="
                          justify-content: center;
                          align-items: center;
                          display: flex;
                          height: 150px;
                        "
                      >
                        <v-img
                          :key="avatarIndex"
                          :src="item.avatar.route"
                          :aspect-ratio="4 / 3"
                          contain
                          v-if="item.avatar && item.avatar.route"
                          alt="Cover"
                        >
                          <v-btn
                            color="primary"
                            font
                            fab
                            x-small
                            absolute
                            right
                            title="Eliminar archivo"
                            style="right: 0"
                            @click="handleDeleteFile(item.avatar.id, 'avatar')"
                            ><i class="fa fa-remove" /></v-btn
                        ></v-img>

                        <v-icon
                          x-large
                          aria-hidden="false"
                          v-if="!item.avatar || !item.avatar.route"
                        >
                          mdi-camera-outline
                        </v-icon>
                      </v-card-text>
                      <v-divider />
                      <v-col cols="12">
                        <v-file-input
                          accept="image/*"
                          v-model="course.avatar"
                          label="Cambiar cover"
                          persistent-hint
                          :hint="'Dimensiones recomendadas: 400x300px'"
                        />
                      </v-col>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="4" md="6">
                    <v-card elevation="4">
                      <v-card-text
                        class="text-center"
                        style="
                          justify-content: center;
                          align-items: center;
                          display: flex;
                          height: 150px;
                        "
                      >
                        <v-img
                          :key="coverIndex"
                          :src="item.cover.route"
                          :aspect-ratio="16 / 9"
                          contain
                          v-if="item.cover && item.cover.route"
                          alt="Banner principal"
                        >
                          <v-btn
                            color="primary"
                            font
                            fab
                            x-small
                            absolute
                            right
                            title="Eliminar archivo"
                            style="right: 0"
                            @click="handleDeleteFile(item.cover.id, 'cover')"
                            ><i class="fa fa-remove" /></v-btn
                        ></v-img>
                        <v-icon
                          fab
                          x-large
                          aria-hidden="false"
                          v-if="!item.cover || !item.cover.route"
                        >
                          mdi-camera-outline
                        </v-icon>
                      </v-card-text>
                      <v-divider />
                      <v-col cols="12">
                        <v-file-input
                          accept="image/*"
                          v-model="course.cover"
                          label="Cambiar banner principal"
                          persistent-hint
                          :hint="'Dimensiones recomendadas: 1200x400px'"
                        />
                      </v-col>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="4" md="6">
                    <v-card elevation="4">
                      <v-card-text
                        class="text-center"
                        style="
                          justify-content: center;
                          align-items: center;
                          display: flex;
                          height: 150px;
                        "
                      >
                        <v-img
                          :key="certificateIndex"
                          :src="item.certificate.route"
                          contain
                          :aspect-ratio="4 / 3"
                          v-if="item.certificate && item.certificate.route"
                          alt="Certificado"
                        >
                          <v-btn
                            color="primary"
                            font
                            fab
                            x-small
                            absolute
                            right
                            title="Eliminar archivo"
                            style="right: 0"
                            @click="handleDeleteFile(item.certificate.id, 'certificate')"
                            ><i class="fa fa-remove" /></v-btn
                        ></v-img>
                        <v-icon
                          x-large
                          aria-hidden="false"
                          v-if="!item.certificate || !item.certificate.route"
                        >
                          mdi-camera-outline
                        </v-icon>
                      </v-card-text>
                      <v-divider />
                      <v-col cols="12">
                        <v-file-input
                          accept="image/*"
                          v-model="course.certificate"
                          label="Cambiar certificado"
                          persistent-hint
                          :hint="'Dimensiones recomendadas: 1000x700px'"
                        />
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>
                <v-btn
                  small
                  color="primary"
                  @click=";(step = 4), createItem()"
                  :disabled="!valid"
                  class="mr-2"
                  v-if="flow === 'create'"
                >
                  Crear
                </v-btn>
                <v-btn
                  v-if="flow === 'edit'"
                  :disabled="!valid"
                  class="mr-2"
                  dark
                  color="primary"
                  @click="saveItem()"
                  small
                >
                  Guardar
                </v-btn>
                <v-btn small @click="step = 2" class="mr-2" color="#adadb0" dark>
                  Volver
                </v-btn>
                <v-btn
                  class="mr-2"
                  color="#adadb0"
                  dark
                  @click="$modal.hide('crudModal')"
                  small
                >
                  Cancelar
                </v-btn>
              </v-stepper-content>
            </v-stepper>
          </v-form>
        </v-row>
      </v-card>
    </modal>
  </div>
</template>

<script>
  import { VueEditor } from 'vue2-editor'
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { toLocaleDateString } from '@/utils/helpers'

  export default {
    name: 'Courses',
    components: {
      SimpleList,
      VueEditor,
    },
    props: {
      company: { type: Object, required: true },
    },
    data() {
      return {
        step: 1,
        config: {},
        courses: [],

        loading3: false,
        loading: false,
        isLoading: false,
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        headers: [
          {
            text: '#',
            value: 'id',
            width: '50px',
          },
          { text: 'Nombre', value: 'name' },
          { text: '# Lecciones', value: 'lessonsCount' },
          // { text: 'Tipo', value: 'type' },
          { text: 'Activo', value: 'activeText' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '150px' },
        ],
        items: [],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
        },
        extraButtoms: [
          {
            text: 'Lecciones',
            icon: 'mdi-order-bool-ascending',
            event: { active: true, name: 'handleLessonsRedirect' },
          },
          {
            text: 'Gestor del Examen',
            icon: 'mdi-clipboard-text-outline',
            event: { active: true, name: 'handleExamRedirect' },
          },
        ],
        course: {
          name: '',
          points: 100,
          description: '',
          active: 1,
          priority: null,
          avatar: null,
          cover: null,
          certificate: null,
        },
        reloadingImage: false,
        avatarIndex: 0,
        coverIndex: 0,
        certificateIndex: 0,
        type: '',
        loadingText: 'Cargando registros',
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    watch: {},
    created() {
      this.getCourses()
      this.findCourses()
    },
    mounted() {},
    computed: {
      widthDialog() {
        return this.$vuetify.breakpoint.smAndDown ? '95%' : '70%'
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,
      cleanFilters() {
        this.filters = {
          search: '',
          periodicity: null,
        }
      },
      findCourses(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('courses?order=name&by=asc&limit=100' + searchStr)
          .then((response) => {
            if (this.item && this.item.id) {
              this.courses = response.data.filter((course) => course.id !== this.item.id)
            } else {
              this.courses = response.data
            }
          })
      },

      async getCourses() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          queryParams = searchString
        }
        this.$axios
          .get(
            'courses?&with[]=lessons&with[]=uploads&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.forEach((course) => {
              course.activeText = course.active ? 'Si' : 'No'
              course.lessonsCount = course.lessons.length
              course.avatar = course.uploads.find((upload) => upload.type === 'avatar')
              course.cover = course.uploads.find((upload) => upload.type === 'cover')
              course.certificate = course.uploads.find(
                (upload) => upload.type === 'certificate'
              )
            })
            this.$emit('loadedCourses', this.items)
            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.course.name = ''
        this.course.description = ''
        this.course.active = 1
        this.course.priority = null
        this.course.avatar = null
        this.course.cover = null
        this.course.certificate = null

        this.step = 1

        this.$modal.show('crudModal')
      },

      async handleLessonsRedirect(course) {
        this.$router.push({
          name: 'business/elearning/CourseLessons',
          params: { courseId: course.id },
        })
      },

      async handleExamRedirect(course) {
        this.$router.push({
          name: 'business/elearning/ExamQuestions',
          params: { courseId: course.id },
        })
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getCourses()
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.step = 1
        this.course = Object.assign(this.course, this.item)
        this.course.avatar = null
        this.course.cover = null
        this.course.certificate = null
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },
      async createItem() {
        this.course.active = this.course.active ? 1 : 0
        const formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        if (this.course.name !== '') {
          formData.append('name', this.course.name)
        }
        if (this.course.description !== '') {
          formData.append('description', this.course.description)
        }
        if (this.course.points) {
          formData.append('points', this.course.points)
        }
        formData.append('active', this.course.active)
        if (this.course.priority && this.course.priority.id) {
          formData.append('course_id', this.course.priority.id)
        }
        if (this.course.avatar) {
          formData.append('files[0][file]', this.course.avatar)
          formData.append('files[0][fileType]', 'avatar')
        }
        if (this.course.cover) {
          formData.append('files[1][file]', this.course.cover)
          formData.append('files[1][fileType]', 'cover')
        }
        if (this.course.certificate) {
          formData.append('files[2][file]', this.course.certificate)
          formData.append('files[2][fileType]', 'certificate')
        }
        this.$axios.post('courses', formData, this.config).then(() => {
          this.page = 1
          this.getCourses()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        this.course.active = this.course.active ? 1 : 0
        const formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        if (this.course.name !== '') {
          formData.append('name', this.course.name)
        }
        if (this.course.description !== '') {
          formData.append('description', this.course.description)
        }
        if (this.course.points) {
          formData.append('points', this.course.points)
        }
        formData.append('active', this.course.active)
        if (this.course.priority && this.course.priority.id) {
          formData.append('course_id', this.course.priority.id)
        }
        if (this.course.avatar) {
          formData.append('files[0][file]', this.course.avatar)
          formData.append('files[0][fileType]', 'avatar')
          if (this.item.avatar) {
            formData.append('files[0][fileId]', this.item.avatar.id)
          }
        }
        if (this.course.cover) {
          formData.append('files[1][file]', this.course.cover)
          formData.append('files[1][fileType]', 'cover')
          if (this.item.cover) {
            formData.append('files[1][fileId]', this.item.cover.id)
          }
        }
        if (this.course.certificate) {
          formData.append('files[2][file]', this.course.certificate)
          formData.append('files[2][fileType]', 'certificate')
          if (this.item.certificate) {
            formData.append('files[2][fileId]', this.item.certificate.id)
          }
        }
        formData.append('_method', 'patch')
        this.$axios.post('courses/' + this.item.id, formData, this.config).then(() => {
          this.page = 1
          this.getCourses()
          this.$modal.hide('crudModal')
        })
      },

      handleImageAdded(file, Editor, cursorLocation, resetUploader) {
        if (this.flow === 'edit') {
          const formData = new FormData()
          formData.append('file', file)
          formData.append('fileType', 'image')
          formData.append('_method', 'patch')

          this.$axios.post('courses/' + this.item.id, formData).then((response) => {
            const url = response.data.course.latest_upload.route // Get url from response
            Editor.insertEmbed(cursorLocation, 'image', url)
            resetUploader()
          })
        } else {
          this.$swal({
            title: 'Ups...',
            text: 'Para agregar imágenes primero debes crear la publicación. ',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'De acuerdo',
          })
          resetUploader()
        }
      },

      async deleteItem(item) {
        this.$axios.delete('courses/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getCourses()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getCourses()
      },
      handleDeleteFile(id, fileType) {
        this.course.avatar = null
        this.item.avatar = null
        this.deleteFile(id, fileType)

        // this.fileIndex++
      },
      async deleteFile(id, fileType) {
        this.reloadingImage = true
        this.$axios.delete('/upload-management/delete-upload/' + id).then(() => {
          this.loading = false
          this.$swal({
            icon: 'success',
            title: `Eliminado`,
            showCancelButton: false,
            showConfirmButton: true,
          })
          switch (fileType) {
            case 'avatar':
              this.avatarIndex++
              break
            case 'cover':
              this.coverIndex++
              break
            case 'certificate':
              this.certificateIndex++
              break
            default:
              break
          }
          this.item.uploads = this.item.uploads.filter((file) => file.id !== id)
        })
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  .courses {
    .v-stepper {
      box-shadow: none;
    }
    .v-stepper--vertical .v-stepper__content {
      margin: -8px 12px;
    }
    // .v-stepper--vertical .v-stepper__content {
    //   width: calc(100% - 40px);
    // }
    .v-stepper--vertical .v-stepper__step {
      padding: 16px 0px;
    }
    // .v-application--is-ltr
    //   .v-stepper--vertical
    //   .v-stepper__content.v-application--is-ltr
    //   .v-stepper--vertical
    //   .v-stepper__content {
    //   margin: -8px -10px -16px 12px !important;
    // }
    .v-responsive {
      height: 100%;
      width: 100%;
    }
  }
</style>
